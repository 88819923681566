export class DailyWorkspace {
    public dailyPlan: boolean;
    public analyzeTrades: boolean;
    public importTrades: boolean;
    public addNotes: boolean;
    public reviewPerformance: boolean;
    public reviewStats: boolean;
    public addRules: boolean;
    public day: Date;
}
