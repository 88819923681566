// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://us.cuetrade.io/service',
  redirectUri: 'https://us.cuetrade.io/oauth-redirect',
  tradingServiceUri: 'https://trading.cuetrade.io/tradingservice',
  websocketUrl: 'https://trading.cuetrade.io',
  //Uncomment this only in local
  // env: 'local',
  //Comment this only in local
  env: 'prod',
  snaptradeurl:'https://trading.cuetrade.io',
  clientCode: 'GPJIG4CRFK-102',
  authToken: '',
  tz: 'America/New_York'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
