export class UserStockSummary {

    open: number;

    high: number;

    low: number;

    close: number;

    volume: number;

    changePercent: number;

    winPercent: number;

    lossPercent: number;

    winLossRatio: number;

    netReturn: number;

    avgHoldingPeriod: number;

    successStrategy: String;

    highGain: number;

    marketCapitalBln: number;

    week52High: number;

    week52Low: number;

    avgVolumeMln: number;

    largestLoss: number;

    revenueBln: number;

    grossMargin: number;

    operatingMargin: number;

    freeCashFlowBln: number;

    totalDebt: number;

    eps: number;

    bookValueBln: number;

    beta: number;

}