export class UserComment {

    name: string;

    email: string;

    description: string;

    postAllowed: boolean;

    contact: boolean;

}
