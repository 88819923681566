export class SummaryRequest {

    summaryType: string;

    summaryTypes: string[];

    fromDate: string;
    
    toDate: string;

    stockId: number;

    strategyTypeId: number;

    symbol: string;

    day: string;
    
}
