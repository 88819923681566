export class StockSummaryResult {

    realizedReturn: number;

    winRate: number;
    
    avgHoldingPeriod: number;

    highestReturn: number;

    largestLoss: number;

    successStrategy: string;

    failedStrategy: string;

    totalTransactions: number;

    totalStrategies: number;

}
